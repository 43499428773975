import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from 'app/@core/utils';
import { isNull, isUndefined, isArrayLikeObject, isObject } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private baseUrl = '/api/user';

  constructor(
      private apiService: ApiService,
  ) { }

  me(): Observable<any> {
    const url = `${this.baseUrl}/me`;
    return this.apiService.get(url);
  }

  updateProfile(id: string, data: object) {
    const url = `${this.baseUrl}/${id}/update-profile`;
    return this.apiService.put(url, data);
  }

  changePhoto(id: string, data: any) {
    const url = `${this.baseUrl}/${id}/change-photo`;
    return this.apiService.post(url, data);
  }

  changePassword(username: string, data: object): Observable<any> {
    const url = `${this.baseUrl}/${username}/change-password`;
    return this.apiService.post(url, data);
  }
}
