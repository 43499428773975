import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { ApiService } from "app/@core/utils";
import { HttpParamsService } from "app/@core/utils";

export interface agencyData {
  rev_code: string;
  rev_no: string;
  rev_name: string;
  is_active: number;
  remarks: string;
  created_by: string;
  created_at: Date;
  updated_by: string;
  updated_at: Date;
  mun_code: string;
  total_dec: number;
  total_ine: number;
  total_vac: number;
  total_vac_sin_a: number;
  total_vac_sin_b: number;
  total_vac_ast_a: number;
  total_vac_ast_b: number;
}

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  constructor(
      private apiService: ApiService,
      private httpParams: HttpParamsService
  ) { }

  charRrIss(params: object = {}): Observable<any[]> {
      const parameters = this.httpParams.convert(params);
      return this.apiService.get('/api/rr_iss', parameters);
  }

  chartBreakdown(params: object = {}): Observable<any[]> {
    const parameters = this.httpParams.convert(params);
    return this.apiService.get('/api/chart_breakdown', parameters);
  }

  stockcard(params: object = {}): Observable<any[]> {
    const parameters = this.httpParams.convert(params);
    return this.apiService.get('/api/stockcard', parameters);
  }

  agencies(params: object = {}): Observable<agencyData[]> {
    const parameters = this.httpParams.convert(params);
    return this.apiService.get('/api/char_agencies', parameters);
  }

  chartProfile(params: object = {}): Observable<any[]> {
    const parameters = this.httpParams.convert(params);
    return this.apiService.get('/api/chart_profile', parameters);
  }

  chartProfileLgu(params: object = {}): Observable<any[]> {
    const parameters = this.httpParams.convert(params);
    return this.apiService.get('/api/chart_lgu_detail', parameters);
  }

  chartIssLgu(params: object = {}): Observable<any[]> {
    const parameters = this.httpParams.convert(params);
    return this.apiService.get('/api/chart_lgu_iss', parameters);
  }

  lastTransaction(params: object = {}): Observable<any[]> {
    const parameters = this.httpParams.convert(params);
    return this.apiService.get('/api/last_modified', parameters);
  }

  chartAgeLgu(params: object = {}): Observable<any[]> {
    const parameters = this.httpParams.convert(params);
    return this.apiService.get('/api/chart_age_lgu', parameters);
  }

  chartAgeBrgy(params: object = {}): Observable<any[]> {
    const parameters = this.httpParams.convert(params);
    return this.apiService.get('/api/chart_age_brgy', parameters);
  }

  dataAgeBrgy(params: object = {}): Observable<any[]> {
    const parameters = this.httpParams.convert(params);
    return this.apiService.get('/api/data_age', parameters);
  }
  
  chartPrecinct(params: object = {}): Observable<any[]> {
    const parameters = this.httpParams.convert(params);
    return this.apiService.get('/api/chart_precinct', parameters);
  }

  chartPrecinctLGU(params: object = {}): Observable<any[]> {
    const parameters = this.httpParams.convert(params);
    return this.apiService.get('/api/chart_precinct_lgu', parameters);
  }
}
