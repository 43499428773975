import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LogoService {
  
  private logoEvent = new Subject<any>();
  setLogoEvent(url: string) {
    this.logoEvent.next(url);
  }
  getLogoEvent(): Observable<any> {
    return this.logoEvent.asObservable();
  }
}