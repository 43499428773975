import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { ApiService } from "app/@core/utils";
import { HttpParamsService } from "app/@core/utils";

@Injectable({
  providedIn: 'root'
})
export class ManualDrtService {

  constructor(
      private apiService: ApiService,
      private httpParams: HttpParamsService,
  ) { }

  hosp_coll_summ(params: object = {}): Observable<any[]> {
      const parameters = this.httpParams.convert(params);
      return this.apiService.get('/api/hospital_collections_summary', parameters);
  }

  pmrb_coll(params: object = {}): Observable<any[]> {
    const parameters = this.httpParams.convert(params);
    return this.apiService.get('/api/pmrb_collections', parameters);
}

  tms_rc(params: object = {}): Observable<any[]> {
    const parameters = this.httpParams.convert(params);
    return this.apiService.get('/api/tms_rc', parameters);
  }

  tms_obr_export(params: object = {}): Observable<any[]> {
    const parameters = this.httpParams.convert(params);
    return this.apiService.get('/api/tms_obr_export', parameters);
  }

  main_rcd(params: object = {}): Observable<any[]> {
    const parameters = this.httpParams.convert(params);
    return this.apiService.get('/api/main_rcd', parameters);
}
}
