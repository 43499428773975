import { Injectable }  from '@angular/core';
import { UserService } from 'app/@core/services';
import { LocalStorageService } from 'ngx-webstorage';
import { NgxRolesService } from 'ngx-permissions';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { Router } from '@angular/router';

@Injectable()
export class AppInitService {
 
    constructor(
        private rolesService: NgxRolesService,
        private userService: UserService,
        private localStorage: LocalStorageService,
        private router: Router,
        private authService: NbAuthService,
    ) { }
    
    Init() {
 
        return new Promise<void>((resolve, reject) => {
            console.log("AppInitService.init() called");
            this.authService.isAuthenticated()
              .subscribe((authenticated) => {
                console.log(authenticated)
                if (authenticated) {
                  this.getMe(resolve);
                } else {
                  resolve();
                }
              });
        });
    }

    
    getMe(resolve) {
      const self = this;
      self.localStorage.clear('current_user');
      self.userService.me()
      .pipe()
        .subscribe((res) => {
          
          self.localStorage.store('current_user', {
            username: res['username'],
            displayName : res['accountName'],
            photoUrl: res['photoUrl']
          });

          console.log('ROLES:', res['rolePermissions']);
          res['rolePermissions'].map(data => {
            this.rolesService.addRoleWithPermissions(data.role, data.permission);
          });
          resolve();
      }, (error) => {
        if(error.status === 401) {
          resolve();
          this.router.navigateByUrl('auth/logout');
        }
        
      });
    }
}