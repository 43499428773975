
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER  } from '@angular/core';
import { HttpClientModule, HttpRequest } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
  NbIconLibraries
} from '@nebular/theme';

import { NbAuthJWTInterceptor } from '@nebular/auth';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from '@nebular/auth';
import { HttpConfigInterceptor } from './@core/interceptors/httpconfig.interceptors';

import { AuthGuard } from './@core/guards/auth-guard';

import { NgxPermissionsModule } from 'ngx-permissions';
import { MomentModule } from 'ngx-moment';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgxMaskModule } from 'ngx-mask';

import { AppInitService } from './app-init.service';

export function otherValidationMessage(err, field) {
  return err;
}

export function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => { 
    return appInitService.Init();
  }
}

import { CustomNbIconLibraries } from "./custom-nb-icon-libraries";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,

    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),

    NgxPermissionsModule.forRoot(),
    MomentModule.forRoot(),
    NgxWebstorageModule.forRoot({ prefix: '', separator: '', caseSensitive:true }),
    NgxMaskModule.forRoot(),
    
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  providers: [
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp, 
      deps: [AppInitService],
      multi: true
    },
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NbAuthJWTInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true,
    },
    {
      provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
      useValue: function (req: HttpRequest<any>) {
        if (req.url === '/api/token/refresh') {
          return true;
        }

        if (req.url === '/api/login_check') {
          return true;
        }

        return false;
      },
    },
    { provide: NbIconLibraries, useExisting: CustomNbIconLibraries }
  ],
})
export class AppModule {
  constructor(private iconLibraries: NbIconLibraries) {
    this.iconLibraries.registerFontPack('font-awesome', { iconClassPrefix: 'fa', packClass: 'fa' });
    this.registerMaterialIcons();
  }

  private registerMaterialIcons() {
    this.iconLibraries.registerFontPack("material-icons", {
      packClass: "material-icons",
      ligature: true
    });
    this.iconLibraries.setDefaultPack("material-icons");
  }
}
