import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { isNull, isUndefined } from 'lodash';

import { LocalStorageService } from 'ngx-webstorage';
import { LogoService } from 'app/@core/services/logo.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
    {
      value: 'material-light',
      name: 'Material Light',
    },
    {
      value: 'material-dark',
      name: 'Material Dark',
    },
  ];

  currentTheme = 'default';

  logo: string = './assets/images/header_title.png';
  userMenu = [{ title: 'Profile', icon: 'person-outline', link: '/pages/profile' },
    { title: 'Change Password', icon: 'lock-outline', link: '/pages/profile/change-password' },
    { title: 'Log out', icon: 'corner-down-left-outline', link: '/auth/logout' }];

  constructor(private sidebarService: NbSidebarService,
              private _logoService: LogoService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private localStorageService: LocalStorageService) 
  {
    this._logoService.getLogoEvent().subscribe((data)=> {
      this.logo = data;
    });
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    const currentUser = this.localStorageService.retrieve('current_user');
    console.log(currentUser);
    const photo = currentUser?.photoUrl;
    const displayName = currentUser?.displayName;
    const username = currentUser?.username;
    this.user = {
      name: (displayName === '' || isNull(displayName) || isUndefined(displayName)) ? username : displayName,
      picture: (isUndefined(photo) || photo === null) ? 'assets/images/default.png' : photo,
    };

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 120);
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
