import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError  } from 'rxjs';
import { catchError, tap, retry } from 'rxjs/operators';

// tslint:disable:no-console

@Injectable()
export class ApiService {

  constructor(
      private http: HttpClient,
  ) { }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get<any>(path , { params })
    .pipe(
        tap(data => console.log('fetched data')),
        catchError(this.handleError('error', [])),
    );
  }

  post(path: string, params: object = {}): Observable<any> {
    return this.http.post<any>(path, params).pipe(
      tap((data: any) => console.log(`added`)),
      catchError(this.handleError<any>('error')),
    );
  }

  put(path: string, params: object = {}): Observable<any> {
    return this.http.put<any>(path, params).pipe(
      tap((data: any) => console.log(`updated`)),
      catchError(this.handleError<any>('error')),
    );
  }

  delete(path: string): Observable<any> {
    return this.http.delete<any>(path).pipe(
      tap((data: any) => console.log(`deleted`)),
      catchError(this.handleError<any>('error')),
    );
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error);


      // Let the app keep running by returning an empty result.
      // return of(result as T);
      return throwError(error);
    };
  }
}
