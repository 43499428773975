import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError, of } from 'rxjs';
import { map, catchError, retry, delay, retryWhen, concatMap } from 'rxjs/operators';
import { NbToastrService } from '@nebular/theme';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private toastrService: NbToastrService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            retryWhen(errors => errors
                .pipe(
                    concatMap((error, count) => {
                        if (count === 1 && (error.status === 500 || error.status === 0)) {
                            return of(error.status);
                        }

                        return throwError(error);
                    }),
                ),
            ),
            map((event: HttpEvent<any>) => {
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
              switch (error.status) {
                case 403:
                  console.log(error);
                  console.log('Permission Denied. ☹️', error.status.toString());
                  this.toastrService.show('Permission Denied. ☹️',
                    'Error ' + error.status.toString(),
                    { status: 'danger'});
                  break;
                case 404:
                  console.log('No such resource found ☹️', error.status.toString());
                  this.toastrService.show('No such resource found ☹️',
                    'Error ' + error.status.toString(),
                    { status: 'danger' });
                  break;
                case 500:
                  console.log('An unknown server error has occurred ☹️', error.status.toString());
                  this.toastrService.show('An unknown server error has occurred ☹️',
                    'Error ' + error.status.toString(),
                    { status: 'danger' });
                  break;
              }
                return throwError(error);
            }));
    }
}
