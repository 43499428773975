<div class="header-container">
  <div class="logo-container">
    <a
      href="#"
      class="sidebar-toggle"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true"
      (click)="toggleSidebar()" >
      <nb-icon icon="menu-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()" style="background: url({{logo}}) no-repeat; width: 170px; height: 40px;border-left: 0px !important;"></a>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>

<div class="header-container">
  <nb-actions size="small">

    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <nb-action 
      class="user-action">
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
