import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { ApiService } from "app/@core/utils";
import { HttpParamsService } from "app/@core/utils";

@Injectable({
  providedIn: 'root'
})
export class TmsManualService {

  constructor(
      private apiService: ApiService,
      private httpParams: HttpParamsService,
  ) { }

  tms_fy(params: object = {}): Observable<any[]> {
      const parameters = this.httpParams.convert(params);
      return this.apiService.get('/api/tms_fy', parameters);
  }

  tms_rc(params: object = {}): Observable<any[]> {
    const parameters = this.httpParams.convert(params);
    return this.apiService.get('/api/tms_rc', parameters);
  }

  tms_cluster(params: object = {}): Observable<any[]> {
    const parameters = this.httpParams.convert(params);
    return this.apiService.get('/api/tms_cluster', parameters);
  }

  tms_obr_export(params: object = {}): Observable<any[]> {
    const parameters = this.httpParams.convert(params);
    return this.apiService.get('/api/tms_obr_export', parameters);
  }

}
