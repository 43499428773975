import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders  } from '@angular/common/http';
import { isNull, isUndefined, isArrayLikeObject, isObject } from 'lodash';

@Injectable()
export class HttpParamsService {

  constructor() { }

  convert(options: object) {
    let params = new HttpParams();
    for (const key in options) {
      if (key === 'filter') {
        if (isObject(options[key])) {
          for (const f in options[key]) {
            if (!isNull(options[key][f]) && !isUndefined(options[key][f]) && options[key][f] !== '') {
              if (isObject(options[key][f])) {
                for (const ff in options[key][f]) {
                  if (!isNull(options[key][f][ff]) && !isUndefined(options[key][f][ff]) && options[key][f][ff] !== '') {
                    params = params.append(f + `[` + ff + `]`, options[key][f][ff]);
                  }
                }
              } else {
                params = params.append(f, options[key][f]);
              }
            }
          }
        }
      } else if (key === 'order') {
        if (isArrayLikeObject(options[key])) {
          options[key].forEach(element => {
            params = params.append(`order[` + element.sortColumn + `]`, element.sortDirection);
          });
        }
      } else {
        if (!isNull(options[key]) && !isUndefined(options[key]) && options[key] !== '') {
          params = params.append(key, options[key]);
        }
      }
    }
    return params;
  }
}
