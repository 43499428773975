import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { ApiService } from "app/@core/utils";
import { HttpParamsService } from "app/@core/utils";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private baseUrl = "/api/vmis-profiles";

  constructor(
      private apiService: ApiService,
      private httpParams: HttpParamsService
  ) { }

  list(params: object = {}): Observable<any[]> {
      const parameters = this.httpParams.convert(params);
      return this.apiService.get(this.baseUrl, parameters);
  }

  get(id: string): Observable<any> {
      const url = `${this.baseUrl}/${id}`;
      return this.apiService.get(url);
  }

  create(data: object): Observable<any> {
      return this.apiService.post(this.baseUrl, data);
  }

  update(id: string, data: object): Observable<any> {
      const url = `${this.baseUrl}/${id}`;
      return this.apiService.put(url, data);
  }

  delete(id: string): Observable<any> {
      const url = `${this.baseUrl}/${id}`;
      return this.apiService.delete(url);
  }
}
