import { Injectable, ApplicationRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SystemConfigService {
  private data = new BehaviorSubject<any>([]);
  currentData = this.data.asObservable();

  constructor(
    private ref: ApplicationRef
  ) { }

  setData(data: any) {
    console.log('Config:', data);
    this.data.next(data);
  }

}
