import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule, NbPasswordAuthStrategy,  NbAuthJWTToken } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  AnalyticsService,
  LayoutService,
  ApiService,
  StateService,
  TitleService,
  ReportPopupHelperService,
  HttpParamsService,
} from './utils';

const DATA_SERVICES = [];

import { RoleProvider } from './role.provider';

export function getter(module: string, res: HttpErrorResponse) {
  return [res.error.message];
}

export const NB_CORE_PROVIDERS = [
  ...DATA_SERVICES,
  ...NbAuthModule.forRoot({

    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'email',
        baseEndpoint: '',
        login: {
          endpoint: '/api/login_check',
          method: 'post',
          defaultMessages: ['Successfully login, redirecting...']
        },
        logout: {
          endpoint: '',
          redirect: {
            success: '/auth/login',
            failure: null,
          },
        },
        refreshToken: {
          endpoint: '/api/token/refresh'
        },
        token: {
          class: NbAuthJWTToken,
          key: 'token',
        },
        errors: {
          getter: getter,
        },
      }),
    ],
    forms: {},
  }).providers,

  NbSecurityModule.forRoot().providers,

  {
    provide: NbRoleProvider, useClass: RoleProvider,
  },
  AnalyticsService,
  LayoutService,
  ApiService,
  StateService,
  TitleService,
  ReportPopupHelperService,
  HttpParamsService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
